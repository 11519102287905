import React from "react"
import TwoColTextImage from "../components/content/TwoColTextImage"
import Layout from "../components/layout"
import Seo from "../components/seo"

import firstImage from "../images/_X2_8903_.png"
import secondImage from "../images/WAITING_087_1_cy copy.png"
import thirdImage from "../images/Freemasons__X2_8609.png"

const NotFoundPage = () => {
  const images = [
    <img
      src={firstImage}
      alt="Lifestyle Mitchells & Butlers "
      className={`aspect-[248/441] object-cover rounded-2xl w-[26.25%] max-w-[26.25%] relative`}
    />,
    <img
      src={secondImage}
      alt="Lifestyle Mitchells & Butlers "
      className={`aspect-[382/679] object-cover rounded-2xl mb-8 w-[40.75%] max-w-[40.75%] relative`}
    />,
    <img
      src={thirdImage}
      alt="Lifestyle Mitchells & Butlers "
      className={`aspect-[281/500] object-cover rounded-2xl mb-16 w-[30%] max-w-[30%] relative`}
    />,
  ]
  return (
    <Layout>
      <Seo title="404: Page Not Found " description="" />
      <div className={`bg-theme-blue-light`}>
        <div className={`container`}>
          <TwoColTextImage
            title="Page Not Found"
            content={
              <>
                <p>You just hit a route that doesn&#39;t exist...</p>
                <a
                  href="/"
                  className={`rounded-lg bg-theme-blue flex items-center min-w-[205px] py-5 px-6 text-theme-white uppercase mb-10 button-shadow hover:bg-theme-blue/80 transition-all tracking-[0.008em] font-bold w-fit`}
                >
                  <svg
                    width="25.271"
                    height="18.918"
                    viewBox="0 0 25.271 18.918"
                    className={`mr-3`}
                  >
                    <g transform="translate(2.118)">
                      <path
                        d="M16.153,9.459a.962.962,0,0,0-.23-.657L9.092.394A1.079,1.079,0,0,0,7.581.236a1.035,1.035,0,0,0-.115,1.471l5.436,6.7H-8.067A1.051,1.051,0,0,0-9.118,9.459,1.051,1.051,0,0,0-8.067,10.51H12.9l-5.436,6.7a1.086,1.086,0,0,0,.141,1.534,1.163,1.163,0,0,0,1.484-.22l6.831-8.408A.887.887,0,0,0,16.153,9.459Z"
                        transform="translate(7 0)"
                        fill="#fff"
                      />
                    </g>
                  </svg>
                  <span>Homepage</span>
                </a>
              </>
            }
            images={images}
            showAppLinks={false}
          />
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
